<template>
  <div class="xunpanbox">
      <div class="xunpancontent qi-colorpointer" v-for="(item,index) in xuanpanimgurl" :key="index" >
        <el-popover
            placement="top"
            width="450"
            trigger="hover"
           >
          <div>{{item.subject}}
                <div>USD {{item.priceRangeLow}}-{{item.priceRangeHigh}}</div>
          </div>
            <a  slot="reference" class="recent" :href="item.productURL" target="_blank">
            <img  class="tobeesx-image-img" :src="item.summImageUrl"/>
            </a>
        </el-popover>
      </div>
  </div>
</template>

<script>
export default{
  name: "xunpan.vue",
  props:{
    xuanpanimgurl:{
      type:Array,
      default(){
        return []
      }
    }
  },
  data(){
    return{

    }
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
.xunpanbox{
  display: flex;
  .xunpancontent{
    width: 48px;
    height: 48px;
    border:1px solid #e6e6e6;
    margin-right: 5px;
    .recent{
      target: "_blank";
      width: 100%;
      height: 100%;
    }
    .tobeesx-image-img{
      width: 48px;
      height: 48px;
    }
  }
}


</style>