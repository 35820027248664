<template>
  <div class="page">
    <div class="title">
      商品监控
      <el-switch
        width="40"
        v-model="isSwitch"
        active-color="#FF6A00"
      ></el-switch>
      <span style="font-size: 12px; margin-left: 15px">自动同步</span>
      <span style="font-size: 14px; margin-left: 5px">
        <el-tooltip placement="top">
          <template slot="content">升级高级版可开启每日自动同步去升级</template>
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>
    </div>
    <div class="main">
      <div class="img">
        <img
          src="https://static01.tobeecloud.com/go-waimao/subscribe-center/no_data_priduct_list.svg"
        />
      </div>
      <div class="content">
        <div class="tip">请先添加监控商品</div>
        <div class="text">
          您当前还没有添加监控的商品，添加后可通过每日更新数据记录产品数据变化趋势
        </div>
        <el-button @click="dialogVisible = true">+ 立即添加</el-button>
        <el-dialog
          :show-close="false"
          :visible.sync="dialogVisible"
          width="550px"
          height="460px"
        >
          <template slot="title">
            <div style="font-size: 15px">添加监控商品</div>
          </template>
          <div style="font-size: 12px; margin-bottom: 10px">
            <span style="color: red">*</span>添加商品链接(最多监控50个商品)
          </div>
          <el-input
            :rows="10"
            type="textarea"
            v-model="shopurl"
            placeholder="请输入Alishopee的商品链接，一行输入一个链接，多个链接请换行按空格、换行隔开"
          ></el-input>
          <div style="font-size: 12px; margin: 10px 0">
            <span>采集间隔（秒）</span>
            <el-tooltip placement="top">
              <template slot="content"
                >默认0.5秒，最大60秒；间隔时间越短，<br />采集速度越快，
                也容易因对方服务器<br />繁忙而导致采集中断。</template
              >
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <el-input-number
            style="width: 205px"
            v-model="num"
            size="mini"
            controls-position="right"
            step="0.01"
            :min="0.0"
            placeholder="默认0.5秒，最大可设置60秒"
          ></el-input-number>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false"
              >开始导入</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      shopurl: "",
      num: undefined,
      isSwitch: true,
    };
  },
  methods: {},
};
</script>
<style  scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  color: #313131;
}

.main {
  min-width: 780px;
  height: 400px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 #ddd;
  border-radius: 12px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img {
  width: 240px;
  height: 240px;
  margin-right: 30px;
}

.tip {
  font-size: 18px;
}
.text {
  font-size: 12px;
  color: rgb(140, 140, 140);
  margin: 15px 0;
}
.el-button {
  background-color: #ff6a00;
  color: #fff;
  border-radius: 8px;
  border: none;
}
.el-button:hover {
  color: #ddd;
}
</style>