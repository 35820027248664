<template>
  <div>
    <template v-if="seted === 1">
      <div class="title" style="margin-top: 5px">
        <span>RFQ发布监控</span>
      </div>

      <div class="content-box">
        <el-row style="display: flex">
          <el-col :span="8">
            <el-button type="primary" size="mini" @click="seted = 2">设置订阅规则</el-button>
            <el-button type="default" size="mini" @click="deletedata">删除</el-button>
          </el-col>
          <el-col :span="16" style="text-align: right">
            <el-button size="mini" @click="bqRfq">一键补全数据</el-button>
            <el-button size="mini" @click="Screen = true">筛选</el-button>
            <el-button size="mini" @click="beforExportData" :disabled="dcsj">导出数据</el-button>
          </el-col>
        </el-row>

        <el-dialog title="筛选" :visible.sync="Screen" width="650px" height="50%" class="sxdialog">
          <hr style="margin-bottom: 20px; color: #cccccc" />
          <div
            style="
              width: 100%;
              height: 520px;
              overflow: auto;
              white-space: nowrap;
            "
          >
            <el-form
              ref="sxForm"
              :rules="dialogrules"
              :model="dialogForm"
              label-width="180px"
              size="mini"
            >
              <el-form-item label="类目:">
                <el-cascader :options="options" :props="props" @change="genCategroy" clearable></el-cascader>
              </el-form-item>

              <el-form-item label="关键词:">
                <el-select v-model="dialogForm.keyword" placeholder="不限">
                  <el-option
                    v-for="item in kwOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="订阅地区:">
                <el-cascader
                  :options="optionsRegion"
                  :props="dialogprops"
                  @change="genRegin"
                  clearable
                ></el-cascader>
              </el-form-item>

              <el-form-item label="商机类型:">
                <el-select
                  v-model="dialogForm.rfqLeve"
                  multiple
                  placeholder="请选择"
                  ref="OpportunityType"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="采购量:">
                <div style="display: flex">
                  <el-form-item prop="quantitystart">
                    <el-input-number
                      v-model="dialogForm.quantitystart"
                      controls-position="right"
                      placeholder="最少"
                      @change="cgmin"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <span>-</span>
                  <el-form-item prop="quantityend">
                    <el-input-number
                      v-model="dialogForm.quantityend"
                      controls-position="right"
                      placeholder="最多"
                      @chang="cgmax"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item label="订单预算:">
                <div style="display: flex">
                  <el-form-item prop="yusuanstart">
                    <el-input-number
                      v-model="dialogForm.yusuanstart"
                      controls-position="right"
                      :min="0"
                      @change="ddysmin"
                      placeholder="最少"
                    ></el-input-number>
                  </el-form-item>
                  <span>-</span>
                  <el-form-item prop="yusuanend">
                    <el-input-number
                      v-model="dialogForm.yusuanend"
                      controls-position="right"
                      :min="0"
                      @change="ddysmax"
                      placeholder="最多"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item label="买家最近访问网站时间:">
                <el-date-picker
                  :picker-options="pickerOptions"
                  prefix-icon=" "
                  clear-icon=" "
                  v-model="dialogForm.fwTime"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="剩余席位:">
                <div style="display: flex">
                  <el-form-item prop="rfqLeftCountstart">
                    <el-input-number
                      v-model="dialogForm.rfqLeftCountstart"
                      controls-position="right"
                      :min="0"
                      @change="syxwmin"
                      placeholder="最少"
                    ></el-input-number>
                  </el-form-item>
                  <span>-</span>
                  <el-form-item prop="rfqLeftCountend">
                    <el-input-number
                      v-model="dialogForm.rfqLeftCountend"
                      controls-position="right"
                      :min="0"
                      @change="syxwmax"
                      placeholder="最多"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item label="近90天登录天数:">
                <div style="display: flex">
                  <el-form-item prop="dltsstart">
                    <el-input-number
                      v-model="dialogForm.dltsstart"
                      controls-position="right"
                      :min="0"
                      @change="ddtsmin"
                      placeholder="最少"
                    ></el-input-number>
                  </el-form-item>
                  <span>-</span>
                  <el-form-item prop="dltsend">
                    <el-input-number
                      v-model="dialogForm.dltsend"
                      controls-position="right"
                      :min="0"
                      @change="ddtsmax"
                      placeholder="最多"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item label="近90天搜索次数:">
                <div style="display: flex">
                  <el-form-item prop="sscsstart">
                    <el-input-number
                      v-model="dialogForm.sscsstart"
                      controls-position="right"
                      :min="0"
                      @change="sscsmin"
                      placeholder="最少"
                    ></el-input-number>
                  </el-form-item>
                  <span>-</span>
                  <el-form-item prop="sscsend">
                    <el-input-number
                      v-model="dialogForm.sscsend"
                      controls-position="right"
                      :min="0"
                      @change="sscsmax"
                      placeholder="最多"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item label="近90天浏览产品:">
                <div style="display: flex">
                  <el-form-item prop="llcpstart">
                    <el-input-number
                      v-model="dialogForm.llcpstart"
                      controls-position="right"
                      :min="0"
                      @change="llcpmin"
                      placeholder="最少"
                    ></el-input-number>
                  </el-form-item>
                  <span>-</span>
                  <el-form-item prop="llcpend">
                    <el-input-number
                      v-model="dialogForm.llcpend"
                      controls-position="right"
                      :min="0"
                      @change="llcpmax"
                      placeholder="最多"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item label="近90天有效询盘数:">
                <div style="display: flex">
                  <el-form-item prop="yxxpstart">
                    <el-input-number
                      v-model="dialogForm.yxxpstart"
                      controls-position="right"
                      :min="0"
                      @change="yxxpsmin"
                      placeholder="最少"
                    ></el-input-number>
                  </el-form-item>
                  <span>-</span>
                  <el-form-item prop="yxxpend">
                    <el-input-number
                      v-model="dialogForm.yxxpend"
                      controls-position="right"
                      :min="0"
                      @change="yxxpsmax"
                      placeholder="最多"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item label="近90天有效RFQ数:">
                <div style="display: flex">
                  <el-form-item prop="ValidRFQmin">
                    <el-input-number
                      v-model="dialogForm.ValidRFQmin"
                      controls-position="right"
                      :min="0"
                      @change="yxrfqmin"
                      placeholder="最少"
                    ></el-input-number>
                  </el-form-item>
                  <span>-</span>
                  <el-form-item prop="ValidRFQmax">
                    <el-input-number
                      v-model="dialogForm.ValidRFQmax"
                      controls-position="right"
                      :min="0"
                      @change="yxrfqmax"
                      placeholder="最多"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item label="近90天RFQ收到报价数:">
                <div style="display: flex">
                  <el-form-item prop="rfqBjstart">
                    <el-input-number
                      v-model="dialogForm.rfqBjstart"
                      controls-position="right"
                      :min="0"
                      @change="sdbjNummin"
                      placeholder="最少"
                    ></el-input-number>
                  </el-form-item>
                  <span>-</span>
                  <el-form-item prop="rfqBjend">
                    <el-input-number
                      v-model="dialogForm.rfqBjend"
                      controls-position="right"
                      :min="0"
                      @change="sdbjNummax"
                      placeholder="最多"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item label="近90天查看报价数:">
                <div style="display: flex">
                  <el-form-item prop="ckbjstart">
                    <el-input-number
                      v-model="dialogForm.ckbjstart"
                      controls-position="right"
                      :min="0"
                      @change="lookNummin"
                      placeholder="最少"
                    ></el-input-number>
                  </el-form-item>
                  <span>-</span>
                  <el-form-item prop="ckbjend">
                    <el-input-number
                      v-model="dialogForm.ckbjend"
                      controls-position="right"
                      :min="0"
                      @change="lookNummax"
                      placeholder="最多"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item>
                <el-button @click="Screen = false">重 置</el-button>
                <el-button type="primary" @click="dialogsx">筛 选</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-dialog>
        <div class="sticky-head" style="margin-top: 10px">
          <el-table
            :row-key="getRowKeys"
            @select="selecttable"
            :data="tableData"
            tooltip-effect="dark"
            ref="multipleTable"
            @select-all="tableSelectAll"
            @selection-change="handleSelectionChange"
            style="width: 100%"
            height="700"
          >
            <el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
            <el-table-column fixed label="RFQ" width="300">
              <template slot-scope="scope">
                <div style="display: flex">
                  <div
                    style="
                      width: 60px;
                      height: 60px;
                      border: 1px solid #ccc;
                      margin: 12px 2px;
                    "
                  >
                    <i class="el-icon-link" style="font-size: 20px; position: absolute; z-index: 1"></i>
                    <el-image :src="scope.row.base.imageUrl" style="height: 50px; width: 50px">
                      <div slot="error" class="image-slot">
                        <!--                        <i class="el-icon-picture-outline"></i>-->
                      </div>
                    </el-image>
                  </div>
                  <div style="flex: 1">
                    <template>
                      <div>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="scope.row.base.subject"
                          placement="top-start"
                        >
                          <span slot="content" v-html="scope.row.base.subject"></span>
                          <div
                            style="display: -webkit-box"
                            v-html="scope.row.base.subject"
                            @click="goUrl(scope.row.base.url)"
                            class="overFlow pointers"
                          ></div>
                        </el-tooltip>
                      </div>
                      <div style="height: 56px">
                        <el-image
                          :src="scope.row.tagImgUrl"
                          style="width: 80px; height: 20px; vertical-align: top"
                        ></el-image>
                        <span>
                          {{ scope.row.desc.postDate }}
                          <span>(U.S. PST)</span>
                        </span>
                      </div>
                    </template>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="关键词" width="120">
              <template slot-scope="scope">{{ scope.row.key }}</template>
            </el-table-column>
            <el-table-column label="类目" width="230">
              <template slot-scope="scope">
                <template v-if="scope.row.desc.userType">
                  <template
                    v-for="(item, index) in scope.row.desc.breadcrumb.list"
                    v-if="
                      scope.row.desc.breadcrumb &&
                      scope.row.desc.breadcrumb.list
                    "
                  >{{ item.val }}>></template>
                  <span>{{ scope.row.desc.breadcrumb.last.val }}</span>
                </template>
                <template v-else>-</template>
              </template>
            </el-table-column>

            <el-table-column label="贸易条款" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.isLoged">
                  <!--TODO -->
                </span>

                <qiu1 v-else :loginurl="scope.row.base.quoteUrl"></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="采购量" width="300">
              <template slot-scope="scope">
                <template>{{ scope.row.base.quantity }}</template>
                <template>{{ scope.row.base.quantityUnit }}</template>
              </template>
            </el-table-column>
            <el-table-column label="订单预算" width="120">
              <!--TODO-->
            </el-table-column>

            <el-table-column label="发布地点" width="120">
              <div slot-scope="scope">
                <el-image style="width: 10px; height: 10px" :src="scope.row.desc.countryUrl"></el-image>
                <span>{{ scope.row.base.country }}</span>
              </div>
            </el-table-column>
            <el-table-column label="目的国" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.isLoged">{{ scope.row.base.purpoesCountry }}</span>
                <qiu1 v-else :loginurl="scope.row.base.quoteUrl"></qiu1>
              </template>
            </el-table-column>

            <el-table-column label="详细描述" width="200">
              <template slot-scope="scope">
                <el-popover placement="top" width="400" trigger="hover">
                  <div>
                    <div style="font-weight: bold">详细描述:</div>
                    <div v-html="scope.row.base.description"></div>
                  </div>
                  <div></div>
                  <span class="overFlow" slot="reference" v-html="scope.row.base.description"></span>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="采购类型" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.isLoged">
                  <!--                TODO -->
                </span>
                <qiu1 v-else :loginurl="scope.row.base.quoteUrl"></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="采购目的" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.isLoged">
                  <!--                TODO -->
                </span>
                <qiu1 v-else :loginurl="scope.row.base.quoteUrl"></qiu1>
              </template>
            </el-table-column>

            <el-table-column label="报价记录(次)" width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.isLoged">
                  <!--                TODO -->
                </span>
                <qiu1 v-else :loginurl="scope.row.base.quoteUrl"></qiu1>
              </template>
            </el-table-column>

            <el-table-column label="买家信息" width="200">
              <div slot-scope="scope">
                <div style="display: flex">
                  <div
                    style="
                      width: 20px;
                      height: 20px;
                      border-radius: 20px;
                      background-color: #ff6600;
                      text-align: center;
                      line-height: 20px;
                    "
                  >
                    <span style="color: white">{{ scope.row.base.buyerName[0] }}</span>
                  </div>
                  <div>{{ scope.row.base.buyerName }}</div>
                  <el-popover placement="top" width="200" height="90" trigger="hover">
                    <div>
                      <span style="font-size: 12px; color: #cccccc">查找用户信息</span>
                      <br />
                      <span style="font-size: 12px">{{ scope.row.base.buyerName }}</span>
                    </div>
                    <i
                      slot="reference"
                      class="el-icon-chat-dot-round pointers"
                      style="
                        font-size: 15px;
                        color: #ff6600;
                        padding: 3px 0 0 5px;
                      "
                    ></i>
                  </el-popover>
                </div>
                <div v-for="(item, index) in scope.row.base.tags">
                  <el-button
                    style="margin: 2px 0"
                    v-if="item.tagName === 'complete_order_via_rfq'"
                    size="mini"
                  >rfq有成交</el-button>
                  <el-button
                    style="margin: 2px 0"
                    v-else-if="item.tagName === 'emailConfirm'"
                    size="mini"
                  >邮件认证</el-button>
                  <el-button
                    style="margin: 2px 0"
                    v-else-if="item.tagName === 'experienced_buyer'"
                    size="mini"
                  >历史订单较高</el-button>
                  <el-button
                    style="margin: 2px 0"
                    v-else-if="item.tagName === 'typically_replies'"
                    size="mini"
                  >rfq高效回复</el-button>
                  <el-popover
                    v-else-if="item.tagName === 'interactive_user'"
                    placement="top-start"
                    width="300"
                    trigger="hover"
                    content="买家近30天与国际站卖家有过深度沟通行为"
                  >
                    <el-button slot="reference" style="margin: 2px 0" size="mini">买家互动能力强</el-button>
                  </el-popover>
                </div>
              </div>
            </el-table-column>
            <el-table-column label="最近访问网站时间" width="120">
              <template slot-scope="scope">
                <template v-if="scope.row.isLoged">
                  {{
                  scope.row.desc.postDate
                  }}
                </template>
                <qiu1 v-else :loginurl="scope.row.base.quoteUrl"></qiu1>
              </template>
            </el-table-column>
            <el-table-column width="120" label="近90天登录天数">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew && scope.row.buyerNew.privacyController
                  ? scope.row.buyerNew.privacyController.loginDays90dNum
                  : "-"
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>

            <el-table-column label="近90天搜索次数" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.privacyController &&
                  scope.row.buyerNew.privacyController.search90dNum
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="近90天浏览产品" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.privacyController &&
                  scope.row.buyerNew.privacyController
                  .browseProductTimes90dNum
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="近90天有效询盘数" width="150">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.privacyController &&
                  scope.row.buyerNew.privacyController.mc90dNum
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="近90天有效RFQ数" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.buyerActs &&
                  scope.row.buyerNew.buyerActs.rfq90dAllCnt
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="近90天RFQ收到报价数" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.buyerActs &&
                  scope.row.buyerNew.buyerActs.rfq90dQuotationCnt
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="近90天查看报价数" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.buyerActs &&
                  scope.row.buyerNew.buyerActs.rfq90dViewQuotationCnt
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="近90天买家询盘产品" width="320">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  <!--              {{ scope.row.buyerNew.privacyController && scope.row.buyerNew.privacyController.mc90dNum
                  }}-->
                  <!--              recentViewedProducts-->
                  <xunpan :xuanpanimgurl="scope.row.buyerNew.recentViewedProducts"></xunpan>
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="商业类型" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.buyerProfile &&
                  scope.row.buyerNew.buyerProfile.businessType
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="最常采购类目" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.buyerProfile &&
                  scope.row.buyerNew.buyerProfile.showPreferredCategories
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="公司名称" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.buyerProfileMask &&
                  scope.row.buyerNew.buyerProfileMask.companyName
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="平均采购频率" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.buyerProfile &&
                  scope.row.buyerNew.buyerProfile.purchaseFrequency
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="年采购额" width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.isLoged">
                  {{
                  scope.row.buyerNew.buyerProfile &&
                  scope.row.buyerNew.buyerProfile.purchaseVolume
                  }}
                </div>
                <qiu1 :loginurl="scope.row.url" v-else></qiu1>
              </template>
            </el-table-column>
            <el-table-column label="官网"></el-table-column>
            <el-table-column fixed="right" label="剩余席位" width="120">
              <template slot-scope="scope">
                <div>{{ scope.row.base.rfqLeftCount }}</div>
                <el-button size="mini" type="primary" @click="goBj(scope.row)">去报价</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="text-align: center">
          <!--              page-size表示每页显示个数-->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="params.pagenum"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </template>
    <template v-else-if="seted === 2">
      <div class="title" style="margin-top: 5px">
        <span>
          <i class="el-icon-arrow-left lsou-pointer" @click="seted = 1"></i>
          设置订阅规则
        </span>
      </div>

      <el-card class="content-box">
        <div class="rule">
          订阅规则
          <span class="light">（根据您的设置，符合条件的RFQ将第一时间通知给您）</span>
        </div>
        <div class="box">
          <div class="n1">
            <div class="hlhs pbgcolor"></div>
            <span class="txt">订阅设置</span>
          </div>
          <div class="n2">
            <div class="item">
              <div class="itk">订阅状态</div>
              <div class="itv">
                <el-radio
                  v-model="rfqSetForm.state"
                  :label="1"
                  @change="updateRfqSet({ state: 1 })"
                >开启</el-radio>
                <el-radio
                  v-model="rfqSetForm.state"
                  :label="0"
                  @change="updateRfqSet({ state: 0 })"
                >关闭</el-radio>
              </div>
            </div>
            <div class="item">
              <div class="itk">通知时段</div>
              <div class="itv">
                <el-time-select
                  class="width100"
                  placeholder="起始时间"
                  @change="changgeStartTime"
                  v-model="rfqSetForm.adviceStartTime"
                  :picker-options="{
                    start: '00:00',
                    step: '00:60',
                    end: '23:00',
                  }"
                ></el-time-select>
                <span style="margin: 0 10px">-</span>
                <el-time-select
                  class="width100"
                  placeholder="结束时间"
                  @change="changgeEndTime"
                  v-model="rfqSetForm.adviceEndTime"
                  :picker-options="{
                    start: '01:00',
                    step: '00:60',
                    end: '24:00',
                    minTime: rfqSetForm.adviceStartTime,
                  }"
                ></el-time-select>
                <span class="light" style="margin-left: 10px">不填默认24小时推送</span>
              </div>
            </div>
            <div class="item">
              <div class="itk">通知间隔</div>
              <div class="itv">
                <el-select v-model="rfqSetForm.adviceStep" placeholder="请选择" @change="NoticeRoom">
                  <el-option label="10" :value="10"></el-option>
                  <el-option label="20" :value="20"></el-option>
                  <el-option label="30" :value="30"></el-option>
                  <el-option label="60" :value="60"></el-option>
                  <el-option label="120" :value="120"></el-option>
                </el-select>
              </div>
              <span class="light" style="margin-left: 10px">建议设置间隔为10分钟-60分钟/次</span>
            </div>
            <div class="item">
              <div class="itk">通知方式</div>
              <div class="itv">
                <el-checkbox label="插件通知" v-model="rfqSetForm.notiPlug" disabled></el-checkbox>
                <el-checkbox label="邮箱通知" v-model="rfqSetForm.notiEml" @change="notiEmlChange"></el-checkbox>
                <span style="color: #cccccc" v-if="!rfqSetForm.eml">
                  (未绑定邮箱
                  <span style="color: #ff6600; cursor: pointer" @click="emlDialog = true">立即绑定</span>)
                </span>
                <span style="color: #cccccc" v-else>({{ rfqSetForm.eml }})</span>
              </div>
            </div>
          </div>
        </div>
        <el-card style="margin: 20px 0">
          <div slot="header" style="text-align: center">
            <span>关键词</span>
          </div>
          <div class="n2">
            <div class="item">
              推荐词（{{ rfqSetForm.recommendList.length }}/{{
              maxRecommendLength
              }}）
            </div>

            <div class="item">
              <el-tag
                :v-if="recommendshow"
                v-for="tag in rfqSetForm.recommendList"
                :key="tag.name"
                closable
                @close="delrecommend(tag)"
              >{{ tag }}</el-tag>
            </div>

            <div class="item">
              <el-input v-model="recommend" :maxlength="50" placeholder="请输入推荐词" class="width180"></el-input>
              <el-button style="margin-left: 20px" @click="addrecommend" :disabled="recommendbtn">添加</el-button>
              <el-button
                style="margin-left: 20px"
                @click="openrecommenddia"
                :disabled="recommendbtn"
              >批量添加</el-button>
              <el-dialog title="批量添加推荐词" :visible.sync="openrecommend" width="500px" heght="460px">
                <el-form>
                  <el-form-item>
                    <el-input
                      style="height: 200px"
                      :rows="10"
                      v-model="keywords1"
                      type="textarea"
                      autocomplete="off"
                      :placeholder="
                        '请输入关键词,最多' +
                        (5 - rfqSetForm.recommendList.length) +
                        '个，按Enter换行'
                      "
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="openrecommend = false">取 消</el-button>
                  <el-button type="primary" @click="addrecommenddia">添 加</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
          <div class="n2">
            <div class="item">屏蔽词（{{ rfqSetForm.shieldList.length }}/10）</div>

            <div class="item">
              <el-tag
                :v-show="shieldshow"
                v-for="tag in rfqSetForm.shieldList"
                :key="tag.name"
                closable
                @close="delshield(tag)"
              >{{ tag }}</el-tag>
            </div>

            <div class="item">
              <el-input v-model="shield" :maxlength="50" placeholder="请输入屏蔽词" class="width180"></el-input>
              <el-button style="margin-left: 20px" @click="addshield" :disabled="shieldbtn">添加</el-button>
              <el-button
                style="margin-left: 20px"
                @click="openshield = true"
                :disabled="shieldbtn"
              >批量添加</el-button>
              <el-dialog title="批量添加屏蔽词" :visible.sync="openshield" width="500px" heght="460px">
                <el-form>
                  <el-form-item>
                    <el-input
                      style="height: 200px"
                      :rows="10"
                      v-model="keywords2"
                      type="textarea"
                      autocomplete="off"
                      :placeholder="
                        '请输入关键词,最多' +
                        (10 - rfqSetForm.shieldList.length) +
                        '个，按Enter换行'
                      "
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="openshield = false">取 消</el-button>
                  <el-button type="primary" @click="addshielddia">添 加</el-button>
                </div>
              </el-dialog>
            </div>
          </div>
        </el-card>

        <el-card>
          <div slot="header" style="text-align: center">
            <span>订阅类目</span>
          </div>

          <div class="item">
            推荐类目（{{ rfqSetForm.categoryList.length }}/{{
            maxCategoryLength
            }}个）
          </div>

          <div
            v-if="rfqSetForm.categoryList.length"
            v-for="(item, index) in rfqSetForm.categoryList"
            :key="index"
          >
            <span>{{ item.labels }}</span>
            <i
              class="el-icon-delete lsou-pointer"
              style="margin-left: 5px"
              @click="delCategory(index)"
            ></i>
          </div>

          <div class="item">
            <el-button size="mini" @click="addCategory = true">添加类目</el-button>
          </div>
        </el-card>

        <el-card style="margin: 20px 0">
          <div slot="header" style="text-align: center">
            <span>订阅地区（买家所在地）</span>
          </div>

          <div class="item">推荐地区</div>
          <div class="item" style="display: flex">
            <div
              style="margin-right: 10px"
              v-for="(item, index) in rfqSetForm.regionList"
              :key="index"
            >
              <span>{{ item.codeCnName }}</span>
              <i
                class="el-icon-delete lsou-pointer"
                style="margin-left: 5px"
                @click="delArea(index)"
              ></i>
            </div>
          </div>

          <div class="item">
            <el-button size="mini" @click="addRegion = true">添加地区</el-button>
          </div>
        </el-card>

        <el-card>
          <div slot="header" style="text-align: center">
            <span>其他订阅条件</span>
          </div>

          <div>
            <el-form
              :model="rfqSetForm"
              ref="rfqSetForm"
              :rules="rfqSetFormrules"
              label-width="105px"
            >
              <el-form-item label="采购量范围:" prop="purchasemin">
                <el-input-number
                  style="width: 110px"
                  v-model="rfqSetForm.purchasemin"
                  @change="compare('rfqSetForm')"
                  controls-position="right"
                  size="small"
                  :min="0"
                  placeholder="最小值"
                ></el-input-number>

                <span style="margin: 0 5px">-</span>

                <el-input-number
                  style="width: 110px"
                  v-model="rfqSetForm.purchasemax"
                  @change="compare('rfqSetForm')"
                  controls-position="right"
                  size="small"
                  :min="0"
                  placeholder="最大值"
                ></el-input-number>
              </el-form-item>

              <el-form-item label="买家标签:">
                <el-select
                  ref="BuyerLabel"
                  @change="CheckBuyerLabel"
                  style="width: 500px"
                  v-model="rfqSetForm.value1"
                  multiple
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in tagOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="商机类型:">
                <el-select
                  v-model="rfqSetForm.value2"
                  multiple
                  placeholder="请选择"
                  style="width: 500px"
                  ref="OpportunityType"
                  @change="CheckOpportunity"
                >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="剩余标价席位≥">
                <el-input-number
                  style="width: 110px"
                  controls-position="right"
                  size="small"
                  :min="0"
                  @change="changeNum"
                  v-model="rfqSetForm.num"
                ></el-input-number>个
              </el-form-item>
            </el-form>
          </div>
        </el-card>
        <div style="display: flex; align-items: center; justify-content: center;margin-top:10px">
          <el-button @click="makeSure">确定</el-button>
        </div>
        <el-dialog title="绑定邮箱" :visible.sync="emlDialog" width="30%" :close-on-click-modal="false">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item prop="emlinput" label="邮箱地址">
              <el-input v-model="ruleForm.emlinput" placeholder="请输入有效的邮箱地址"></el-input>
            </el-form-item>

            <el-form-item prop="verificationCode" label="邮箱验证码">
              <el-input v-model="ruleForm.verificationCode" placeholder="输入验证码">
                <el-button slot="append" @click="SendVer" :disabled="kg">
                  {{ countNub }}
                  <template v-if="!isNaN(countNub)">秒后重新发送</template>
                </el-button>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-button style="width: 100%" type="primary" @click="emlConfirm('ruleForm')">立即绑定</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
        <!--      类目的dialog弹框-->
        <el-dialog title="添加类目" :visible.sync="addCategory" width="500px">
          <!--            @click.native="categoryClick"-->
          <el-cascader
            ref="cascaderAddr"
            @change="CheckCategory"
            style="width: 80%; height: 40px"
            placeholder="请选择类目"
            v-model="checkedCategory"
            :options="options"
            :props="leimuprops"
            popper-class="category"
            collapse-tags
            clearable
          ></el-cascader>
          <div v-if="chekdleimu.length">
            你现在选择的是：
            <!--            todo -->
            <!--            {{ chekdleimu }}-->
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="addCategory = false">取 消</el-button>
            <el-button type="primary" @click="trueCheakleimu">确 定</el-button>
          </span>
        </el-dialog>

        <!--     地区的dialog弹框-->
        <el-dialog title="添加地区" :visible.sync="addRegion" width="500px">
          <el-cascader
            ref="cascaderRegion"
            @change="CheckRegion"
            style="width: 80%; height: 40px"
            placeholder="请选择地区"
            :options="optionsRegion"
            :props="propsRegion"
            :show-all-levels="false"
            collapse-tags
            clearable
          ></el-cascader>
          <div v-if="chekddiqu.length">
            你现在选择的是：
            <span style="color: #aaaaaa" v-for="(item, index) in chekddiqu" :key="index">
              <span>{{ item.codeCnName }}</span>
              <template v-if="index !== chekddiqu.length - 1">&nbsp;&nbsp;/&nbsp;&nbsp;</template>
            </span>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addRegion = false">取 消</el-button>
            <el-button type="primary" @click="trueCheakdiqu">确 定</el-button>
          </span>
        </el-dialog>
      </el-card>
    </template>
  </div>
</template>

<script>
import Qiu1 from "@/components/qiu1";
import xunpan from "@/components/xunpan";
import saveAs from "file-saver";
import * as Excel from "exceljs";

export default {
  name: "aliRfqSubscription",
  components: {
    Qiu1,
    xunpan
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      sure: 0,
      kwOptions: [], // 已设定的关键词列表
      dialogForm: {
        categorylist: null,
        countrySimple: "",
        category: [],
        visitLastTimestart: null,
        visitLastTimeend: null,
        fwTime: null, //买家最近访问网站时间
        quantitystart: null, //采购量
        quantityend: null,
        yusuanstart: null,
        yusuanend: null, //订单预算
        rfqLeftCountstart: null, //剩余席位
        rfqLeftCountend: null,
        dltsstart: null, //近90天登录天数
        dltsend: null,
        sscsstart: null,
        sscsend: null, //近90天搜索次数
        llcpstart: null, //近90天浏览产品
        llcpend: null,
        yxxpstart: null, //近90天有效询盘数
        yxxpend: null,
        ValidRFQmin: null, //近90天有效RFQ数
        ValidRFQmax: null,
        rfqBjstart: null, //近90天RFQ收到报价数
        rfqBjend: null,
        ckbjstart: null, //近90天查看报价数
        ckbjend: null,
        rfqLeve: null, //商机类型
        keyword: ""
      }, //筛选dialog表单验证
      checkedCategory: [],
      dialogForm1: {
        fwTime: null, //买家最近访问网站时间
        quantitystart: null, //采购量
        quantityend: null,
        yusuanstart: null,
        yusuanend: null, //订单预算
        rfqLeftCountstart: null, //剩余席位
        rfqLeftCountend: null,
        dltsstart: null, //近90天登录天数
        dltsend: null,
        sscsstart: null,
        sscsend: null, //近90天搜索次数
        llcpstart: null, //近90天浏览产品
        llcpend: null,
        yxxpstart: null, //近90天有效询盘数
        yxxpend: null,
        ValidRFQmin: null, //近90天有效RFQ数
        ValidRFQmax: null,
        rfqBjstart: null, //近90天RFQ收到报价数
        rfqBjend: null,
        ckbjstart: null, //近90天查看报价数
        ckbjend: null,
        rfqLeve: null, //商机类型

        keyword: []
      },
      dcsjtableData: [],
      dcsj: true, //点击导出数据的开关
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: ""
      }, //筛选的表单数据
      dialogrules: {
        quantitystart: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.quantityend) {
                if (value <= this.dialogForm.quantityend) {
                  callback();
                  this.$refs.sxForm.clearValidate(["quantityend"]);
                } else {
                  this.$refs.sxForm.clearValidate(["quantityend"]);
                  callback(new Error("最小值需小于最大值"));
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        quantityend: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.quantitystart) {
                if (value >= this.dialogForm.quantitystart) {
                  callback();
                  this.$refs.sxForm.clearValidate(["quantitystart"]);
                } else {
                  callback(new Error("最大值需大于最小值"));
                  this.$refs.sxForm.clearValidate(["quantitystart"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],

        yusuanstart: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.yusuanend) {
                if (value <= this.dialogForm.yusuanend) {
                  callback();
                  this.$refs.sxForm.clearValidate(["yusuanend"]);
                } else {
                  callback(new Error("最小值需小于最大值"));
                  this.$refs.sxForm.clearValidate(["yusuanend"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        yusuanend: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.yusuanstart) {
                if (value >= this.dialogForm.yusuanstart) {
                  this.$refs.sxForm.clearValidate(["yusuanstart"]);
                  callback();
                } else {
                  this.$refs.sxForm.clearValidate(["yusuanstart"]);
                  callback(new Error("最大值需大于最小值"));
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],

        rfqLeftCountstart: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.rfqLeftCountend) {
                if (value <= this.dialogForm.rfqLeftCountend) {
                  callback();
                  this.$refs.sxForm.clearValidate(["rfqLeftCountend"]);
                } else {
                  callback(new Error("最小值需小于最大值"));
                  this.$refs.sxForm.clearValidate(["rfqLeftCountend"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        rfqLeftCountend: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.rfqLeftCountstart) {
                if (value >= this.dialogForm.rfqLeftCountstart) {
                  callback();
                  this.$refs.sxForm.clearValidate(["rfqLeftCountstart"]);
                } else {
                  callback(new Error("最大值需大于最小值"));
                  this.$refs.sxForm.clearValidate(["rfqLeftCountstart"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],

        dltsstart: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.dltsstart) {
                if (value <= this.dialogForm.dltsend) {
                  callback();
                  this.$refs.sxForm.clearValidate(["dltsend"]);
                } else {
                  callback(new Error("最小值需小于最大值"));
                  this.$refs.sxForm.clearValidate(["dltsstart"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        dltsend: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.dltsstart) {
                if (value >= this.dialogForm.dltsstart) {
                  this.$refs.sxForm.clearValidate(["dltsstart"]);
                  callback();
                } else {
                  callback(new Error("最大值需大于最小值"));
                  this.$refs.sxForm.clearValidate(["dltsstart"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],

        sscsstart: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.sscsend) {
                if (value <= this.dialogForm.sscsend) {
                  callback();
                  this.$refs.sxForm.clearValidate(["sscsend"]);
                } else {
                  callback(new Error("最小值需小于最大值"));
                  this.$refs.sxForm.clearValidate(["sscsend"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        sscsend: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.sscsstart) {
                if (value >= this.dialogForm.sscsstart) {
                  this.$refs.sxForm.clearValidate(["sscsstart"]);
                  callback();
                } else {
                  callback(new Error("最大值需大于最小值"));
                  this.$refs.sxForm.clearValidate(["sscsstart"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],

        llcpstart: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.llcpend) {
                if (value <= this.dialogForm.llcpend) {
                  callback();
                  this.$refs.sxForm.clearValidate(["llcpend"]);
                } else {
                  callback(new Error("最小值需小于最大值"));
                  this.$refs.sxForm.clearValidate(["llcpend"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        llcpend: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.llcpstart) {
                if (value >= this.dialogForm.llcpstart) {
                  callback();
                  this.$refs.sxForm.clearValidate(["llcpstart"]);
                } else {
                  callback(new Error("最大值需大于最小值"));
                  this.$refs.sxForm.clearValidate(["llcpstart"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],

        yxxpstart: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.yxxpend) {
                if (value <= this.dialogForm.yxxpend) {
                  callback();
                  this.$refs.sxForm.clearValidate(["yxxpend"]);
                } else {
                  callback(new Error("最小值需小于最大值"));
                  this.$refs.sxForm.clearValidate(["yxxpend"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        yxxpend: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.yxxpstart) {
                if (value >= this.dialogForm.yxxpstart) {
                  callback();
                  this.$refs.sxForm.clearValidate(["yxxpstart"]);
                } else {
                  callback(new Error("最大值需大于最小值"));
                  this.$refs.sxForm.clearValidate(["yxxpstart"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],

        ValidRFQmin: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.ValidRFQmax) {
                if (value <= this.dialogForm.ValidRFQmax) {
                  callback();
                  this.$refs.sxForm.clearValidate(["ValidRFQmax"]);
                } else {
                  callback(new Error("最小值需小于最大值"));
                  this.$refs.sxForm.clearValidate(["ValidRFQmax"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        ValidRFQmax: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.ValidRFQmin) {
                if (value >= this.dialogForm.ValidRFQmin) {
                  this.$refs.sxForm.clearValidate(["ValidRFQmin"]);
                  callback();
                } else {
                  callback(new Error("最大值需大于最小值"));
                  this.$refs.sxForm.clearValidate(["ValidRFQmin"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],

        rfqBjstart: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.rfqBjend) {
                if (value <= this.dialogForm.rfqBjend) {
                  callback();
                  this.$refs.sxForm.clearValidate(["rfqBjend"]);
                } else {
                  this.$refs.sxForm.clearValidate(["rfqBjend"]);
                  callback(new Error("最小值需小于最大值"));
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        rfqBjend: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.rfqBjstart) {
                if (value >= this.dialogForm.rfqBjstart) {
                  callback();
                  this.$refs.sxForm.clearValidate(["rfqBjstart"]);
                } else {
                  callback(new Error("最大值需大于最小值"));
                  this.$refs.sxForm.clearValidate(["rfqBjstart"]);
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],

        ckbjstart: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.ckbjend) {
                if (value <= this.dialogForm.ckbjend) {
                  callback();
                  this.$refs.sxForm.clearValidate(["ckbjend"]);
                } else {
                  this.$refs.sxForm.clearValidate(["ckbjend"]);
                  callback(new Error("最小值需小于最大值"));
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ],
        ckbjend: [
          {
            validator: (rule, value, callback) => {
              if (this.dialogForm.ckbjstart) {
                if (value >= this.dialogForm.ckbjstart) {
                  this.$refs.sxForm.clearValidate(["ckbjstart"]);
                  callback();
                } else {
                  this.$refs.sxForm.clearValidate(["ckbjstart"]);
                  callback(new Error("最大值需大于最小值"));
                }
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"]
          }
        ]
      },

      tagOptions: [
        {
          value: 0,
          label: "RFQ有成交"
        },
        {
          value: 1,
          label: "历史订单额较高"
        },
        {
          value: 2,
          label: "邮件认证"
        },
        {
          value: 3,
          label: "RFQ高效回复"
        },
        {
          value: 4,
          label: "买家互动能力强"
        },
        {
          value: 5,
          label: "历史联系买家"
        },
        {
          value: 6,
          label: "有交易记录"
        }
      ],
      props: {
        multiple: false,
        checkStrictly: true,
        label: "categoryCnName",
        value: "categoryId",
        children: "children"
      },
      leimuprops: {
        multiple: true,
        checkStrictly: true,
        expandTrigger: "hover",
        label: "categoryCnName",
        value: "categoryId"
      }, //选择类目
      typeOptions: [
        { value: 0, label: "金牌商机" },
        { value: 1, label: "银牌商机" },
        { value: 2, label: "铜牌商机" }
      ],
      propsRegion: {
        multiple: true,
        label: "cnName",
        value: "code",
        children: "countryList",
        checkStrictly: true
      }, //选择地区
      dialogprops: {
        multiple: false,
        label: "cnName",
        // value: "code",
        value: "code",
        children: "countryList"
      },

      addCategory: false,
      keywords2: "",
      keywords1: "",
      chekdleimu: [],
      addRegion: false,
      chekddiqu: [],
      // 临时的选中变量
      categoryListTmp: [],
      CheckRegionList: [],
      openrecommend: false, //推荐词dialog
      openshield: false, //屏蔽关键词dialog
      Screen: false, //一键筛选的dialog开关
      recommendbtn: false, //控制推荐关键词按钮是否禁用
      recommendshow: true, //控制推荐关键词tag的现实
      shieldshow: true, //屏蔽词的tag显示
      shieldbtn: false, //控制屏蔽关键词按钮是否禁用
      recommend: "", //推荐词
      shield: "", //屏蔽词
      tableData: [],
      injectId: null,
      options: [], //类目数据
      optionsRegion: null,

      total: 0,
      seted: -1,
      // 该用户最大可设置数量
      maxRecommendLength: 1,
      // 最大可设置类目数量
      maxCategoryLength: 1,
      // 设置订阅规则 start---------
      rfqSetForm: {
        state: 0,
        adviceStartTime: "00:00",
        adviceEndTime: "00:00",
        adviceStep: 60,
        notiPlug: true,
        notiEml: false,
        eml: false,

        recommendList: [],
        shieldList: [],

        purchasemin: null,
        purchasemax: null,
        //买家标签
        value1: [],
        value2: [],
        num: 0,
        regionList: [],

        categoryList: []
      },
      emlDialog: false,
      ruleForm: {
        emlinput: "", //邮箱地址
        verificationCode: "" //邮箱验证码
      },
      rules: {
        emlinput: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (
                /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(
                  value
                )
              ) {
                callback();
              } else {
                callback(new Error("请输入正确的邮箱地址!"));
              }
            },
            trigger: ["change", "blur"]
          }
        ],
        verificationCode: [
          { required: true, message: "请输入邮箱验证码", trigger: "blur" },
          {
            min: 6,
            max: 6,
            message: "长度在6个字符",
            trigger: "blur"
          }
        ]
      },

      rfqSetFormrules: {
        purchasemin: [
          {
            validator: (rule, value, callback) => {
              if (this.rfqSetForm.purchasemax) {
                if (value <= this.rfqSetForm.purchasemax) {
                  callback();
                } else {
                  callback(new Error("采购范围最大值必须大于最小值"));
                }
              }
            },
            trigger: ["blur", "change"]
          }
        ]
      },

      kg: false,
      countNub: "发送验证码",
      timer: null,

      params: {
        pagenum: 1,
        pageSize: 10
      },
      checkedData: [],

      heartbeatInf: null
    };
  },

  created() {
    this.getInjectId();
    this.getRfqInfo();

    this.getTcData();
    this.axios
      .get("https://api.l-sou.com/static/json/categories.json")
      .then(data => {
        this.options = data.data;
        console.log("this.options", data.data);
      });
    this.axios
      .get("https://api.l-sou.com/static/json/region.json")
      .then(data => {
        data.data.forEach(item => {
          item.disabled = true;
          item.countryList.forEach(value => {
            value.cnName = value.code + "(" + value.cnName + ")";
          });
        });
        this.optionsRegion = data.data;

        console.log("this.optionsRegion0", data.data);
      });
    // 每10秒钟执行
    // this.heartbeatInf = setInterval(this.getRfqInfo, 30 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.heartbeatInf);
  },
  watch: {
    checkedCategory(n, o) {
      if (n.length > this.maxCategoryLength) {
        this.$message.error("最多只能选择" + this.maxCategoryLength + "个类目");
        // this.checkedCategory.splice(0)
        // for (let i = 0; i < o.length; i++) {
        //   this.checkedCategory.push(i)
        // }
      }
    }
  },
  methods: {
    genRegin(e) {
      console.info("  e  ==== =", e);
      this.dialogForm.countrySimple = e[e.length - 1];
    },
    genCategroy(e) {
      this.dialogForm.category = e;
    },
    //实现父级可选
    categoryClick(event) {
      let that = this;
      this.$nextTick(() => {
        let d = document.getElementsByClassName("category");
        // console.log(d);
        if (d[0] && !d[0].onclick) {
          d[0].onclick = function(e) {
            // console.log(e);
            if (e.target.className == "el-cascader-node__label") {
              e.target.previousElementSibling.click();
              that.$refs.cascaderAddr.dropDownVisible = false;
            }
          };
        }
      });
    },
    async getTC(key = null) {
      let data = await this.axios.get("/index/public/getplanning");
      let tcData = {};
      data.data.forEach(item => {
        tcData[item.pkey] = item;
      });
      if (key) {
        return tcData[key];
      }
      return tcData;
    },
    async getTcData() {
      let tcData = await this.getTC("");
      let userInfo = await this.getuserinfo();
      this.maxRecommendLength = userInfo.vipstate
        ? tcData.rfqKw.general
        : tcData.rfqKw.vip;
      this.maxCategoryLength = userInfo.vipstate
        ? tcData.rfqCtg.general
        : tcData.rfqCtg.vip;
    },
    async getuserinfo() {
      let { data } = await this.axios.get("/users/getuserinfo");
      console.info("getuserinfo === ", data);
      return data;
    },
    //
    cgmin(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["quantitystart"]);
      }
      this.$refs.sxForm.clearValidate(["quantityend"]);
    },
    cgmax(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["quantityend"]);
      }
      this.$refs.sxForm.clearValidate(["quantitystart"]);
    },
    ddysmin(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["yusuanstart"]);
      }
      this.$refs.sxForm.clearValidate(["yusuanend"]);
    },
    ddysmax(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["yusuanend"]);
      }
      this.$refs.sxForm.clearValidate(["yusuanstart"]);
    },
    syxwmin(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["rfqLeftCountstart"]);
      }
      this.$refs.sxForm.clearValidate(["rfqLeftCountend"]);
    },
    syxwmax(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["rfqLeftCountend"]);
      }
      this.$refs.sxForm.clearValidate(["rfqLeftCountstart"]);
    },
    ddtsmin(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["dltsstart"]);
      }
      this.$refs.sxForm.clearValidate(["dltsend"]);
    },
    ddtsmax(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["dltsend"]);
      }
      this.$refs.sxForm.clearValidate(["dltsstart"]);
    },
    sscsmin(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["sscsend"]);
      }
      this.$refs.sxForm.clearValidate(["sscsstart"]);
    },
    sscsmax(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["sscsstart"]);
      }
      this.$refs.sxForm.clearValidate(["sscsend"]);
    },
    llcpmin(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["llcpstart"]);
      }
      this.$refs.sxForm.clearValidate(["llcpend"]);
    },
    llcpmax(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["llcpend"]);
      }
      this.$refs.sxForm.clearValidate(["llcpstart"]);
    },
    yxxpsmin(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["yxxpstart"]);
      }
      this.$refs.sxForm.clearValidate(["yxxpend"]);
    },
    yxxpsmax(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["yxxpend"]);
      }
      this.$refs.sxForm.clearValidate(["yxxpstart"]);
    },
    yxrfqmin(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["ValidRFQmin"]);
      }
      this.$refs.sxForm.clearValidate(["ValidRFQmax"]);
    },
    yxrfqmax(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["ValidRFQmax"]);
      }
      this.$refs.sxForm.clearValidate(["ValidRFQmin"]);
    },
    sdbjNummin(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["rfqBjstart"]);
      }
      this.$refs.sxForm.clearValidate(["rfqBjend"]);
    },
    sdbjNummax(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["rfqBjend"]);
      }
      this.$refs.sxForm.clearValidate(["rfqBjstart"]);
    },
    lookNummin(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["ckbjstart"]);
      }
      this.$refs.sxForm.clearValidate(["ckbjend"]);
    },
    lookNummax(value) {
      if (!value) {
        this.$refs.sxForm.clearValidate(["ckbjend"]);
      }
      this.$refs.sxForm.clearValidate(["ckbjstart"]);
    },
    //
    //点击删除表格行数
    async deletedata() {
      // 拿到接口删除指定数据
      console.log("delete");
      if (!this.checkedData.length) {
        this.$alert("请选中数据后执行删除");
        return;
      }

      let dataIds = [];
      this.checkedData.forEach(item => {
        dataIds.push(item.base.id);
      });

      let resultData = await this.axios.post("/subscribe/deluserrfq", {
        data: dataIds
      });
      console.info("resultData", resultData);
      this.checkedData = [];
      this.fetchData();
    },
    //
    //选中单个执行
    selecttable(row) {
      this.dcsjtableData = row;
      if (row.length > 0) {
        this.dcsj = false;
      } else {
        this.dcsj = true;
      }
    },
    //一键筛选
    bqRfq() {
      this.tableData.forEach(item => {
        item.rfqId = item.base.id;
      });

      //  补全rfq
      chrome.runtime.sendMessage(
        this.injectId,
        {
          type: "bqRFQ",
          data: this.tableData
        },
        (e, a) => {
          console.info("e ,a ", e, a);
          if (e.isLoged) {
            this.$alert("已开启数据补全，请耐心等待几分钟后刷新", "补全提醒", {
              confirmButtonText: "确定",
              type: "primary"
            })
              .then(() => {})
              .catch(() => {});
          } else {
            // this.Complete = true;
            this.$confirm("未登录阿里巴巴，请登录后重试", "登录提示", {
              confirmButtonText: "去登陆",
              cancelButtonText: "取消",
              type: "primary"
            })
              .then(() => {
                window.open("https://passport.alibaba.com/icbu_login.htm");
              })
              .catch(() => {});
          }
        }
      );
    },
    //导出数据
    beforExportData() {
      this.exportData();
    },
  
    exportData() {
      let myData = [
        { dataIndex: "kew", title: "关键词" },
        { dataIndex: "gclass", title: "类目" },
        { dataIndex: "companyBusiness", title: "贸易条款" },
        { dataIndex: "caigouliang", title: "采购量" },
        { dataIndex: "orderbudget", title: "订单预算" },
        { dataIndex: "placeofissueImg", title: "发布地点照片" },
        { dataIndex: "placeofissue", title: "发布地点" },
        { dataIndex: "Countryofdestination", title: "目的国 " },
        { dataIndex: "Detaileddescription", title: "详细描述" },
        { dataIndex: "Purchase Type", title: "采购类型" },
        { dataIndex: "Purposes of procurement", title: "采购目的" },
        { dataIndex: "Quotation record", title: "报价记录（次）" },
        { dataIndex: "BuyerInformation", title: "买家信息" },
        { dataIndex: "website time", title: "最近访问网站时间" },
        { dataIndex: "Login days ", title: "近90天登录天数" },
        { dataIndex: "Number of searches", title: "近90天搜索次数" },
        { dataIndex: "Browse Products", title: "近90天浏览产品" },
        { dataIndex: "Validxunpan", title: "近90天有效询盘数" },
        { dataIndex: "ValidRFQ", title: "近90天有效RFQ数" },
        { dataIndex: "Validoffer", title: "近90天RFQ收到报价数" },
        { dataIndex: "ViewQuote", title: "近90天查看报价数" },
        { dataIndex: "Products inquired Buyer", title: "近90天买家询盘产品" },
        { dataIndex: "oftenpurchasegclass", title: "最常采购类目" },
        { dataIndex: "Business type", title: "商业类型" },
        { dataIndex: "corporate name", title: "公司名称" },
        { dataIndex: "Procurement frequency", title: "平均采购频率" },
        { dataIndex: "Annual purchase amount", title: "年采购额" },
        { dataIndex: "Official website", title: "官网" },
        { dataIndex: "Remainingseats", title: "剩余席位" }
      ];
      let header = [];
      myData.forEach(item => {
        header.push(item.title);
      });

      const columns = myData.map(item => {
        return {
          header: item.title,

          key: item.dataIndex
        };
      });

      const dataList = this.dcsjtableData.map(item => {
        let res = {};
        //类目
        let lm = [];
        if (item.desc.breadcrumb && item.desc.breadcrumb.list) {
          item.desc.breadcrumb.list.forEach(item => {
            lm.push(item.val);
          });
        }
        if (item.desc.breadcrumb && item.desc.breadcrumb.last) {
          lm.push(item.desc.breadcrumb.last.val);
        }

        //买家信息的标签
        let mjxx = [];
        item.base.tags.forEach(jItem => {
          if (jItem.tagName === "complete_order_via_rfq") {
            mjxx.push("rfq有成交");
          } else if (jItem.tagName === "emailConfirm") {
            mjxx.push("邮件认证");
          } else if (jItem.tagName === "experienced_buyer") {
            mjxx.push("历史订单较高");
          } else if (jItem.tagName === "typically_replies") {
            mjxx.push("rfq高效回复");
          } else if (jItem.tagName === "interactive_user") {
            mjxx.push("买家互动能力强");
          }
        });
        console.log("20221120", item);
        res.gclass = lm.join(">>");
        // res.kew
        res.kew = item.key;
        res.caigouliang = item.base.quantity + item.base.quantityUnit;
        res.placeofissueImg = item.desc.countryUrl; //发布地点图片
        res.placeofissue = item.base.country;
        res.BuyerInformation = item.base.buyerName + "(" + mjxx.join(",") + ")";
        res.Remainingseats = item.base.rfqLeftCount;
        res.Detaileddescription = item.base.description.replace(
          /(<([^>]+)>)/gi,
          ""
        ); //详细描述
        return res;
      });
      this.setStyle(header, columns, dataList, "商品搜索分析");
    },
    /**
     * 构造
     */
    genQueryParams() {
      console.info("this.dialogForm.category == ", this.dialogForm.category);
      if (this.dialogForm.category && this.dialogForm.category.length) {
        if (this.dialogForm.category.length === 1) {
          this.dialogForm.categorylist = this.dialogForm.category[0] + ",";
        } else {
          this.dialogForm.categorylist = this.dialogForm.category.join(",");
        }
      } else {
        this.dialogForm.categorylist = "";
      }

      if (this.dialogForm.fwTime && this.dialogForm.fwTime.length) {
        this.dialogForm.visitLastTimestart = this.dialogForm.fwTime[0];
        this.dialogForm.visitLastTimeend = this.dialogForm.fwTime[1];
      }

      return Object.assign(
        {
          pagenum: 1,
          pageSize: 10
        },
        this.dialogForm
      );
    },
    //整体表单验证，validate方法
    dialogsx() {
      this.$refs.sxForm.validate(valid => {
        if (valid) {
          // this.params = Object.assign(this.params, this.dialogForm)
          // this.fetchData()

          // 构造请求参数
          console.info("this.dialogForm === ", this.dialogForm);
          this.params = this.genQueryParams();
          console.info("params === ", this.params);
          this.fetchData();
          this.Screen = false;
          //清空导出数据
          this.dcsjtableData = [];

          //清空table选中状态
          this.$refs.multipleTable.clearSelection();
          //禁用导出数据按钮
          this.dcsj = true;
        } else {
          console.log("表单验证未通过");
        }
      });
    },
    getRowKeys(valid) {
      return valid.base.id;
    },
    changeNum() {
      this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
        surplus: this.rfqSetForm.num
      });
    },
    //买家标签
    CheckBuyerLabel(value) {
      if (value) {
        this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
          tag: value
        });
      }
    },
    compare(rfqSetForm) {
      this.$refs[rfqSetForm].validate(valid => {
        if (valid) {
          this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
            buymin: this.rfqSetForm.purchasemin,
            buymax: this.rfqSetForm.purchasemax
          });
        }
      });
    },
    // 选中地区时
    CheckRegion(value) {
      console.info(
        "CheckRegion  == getCheckedNodes",
        this.$refs.cascaderRegion.getCheckedNodes(true)
      );
      console.info(" value ==== ", value);
      if (value) {
        // let cheackRegionList = []
        // this.$refs.cascaderRegion.getCheckedNodes().forEach(item => {
        //   cheackRegionList.push(item.label)
        // })
        // this.chekddiqu = cheackRegionList.join('/')
        let cheackRegionOb = {};

        this.$refs.cascaderRegion.getCheckedNodes(true).forEach(item => {
          let parentData = item.parent.data;
          let parentCode = parentData.code;
          cheackRegionOb[parentCode] = cheackRegionOb[parentCode] || {
            parentData: parentData,
            childData: []
          };
          cheackRegionOb[parentCode].childData.push(item.data);
        });
        // 使用临时变量存储

        this.chekddiqu = [];
        // 检查
        for (const cheackRegionObKey in cheackRegionOb) {
          let d = cheackRegionOb[cheackRegionObKey];
          if (d.childData.length === d.parentData.countryList.length) {
            this.chekddiqu.push(d.parentData);
          } else {
            this.chekddiqu = this.chekddiqu.concat(d.childData);
          }
        }

        // this.regionList = this.chekddiqu
      } else {
      }
    },
    parsechekddiqu(data) {
      let tableData = [];
      data.forEach(item => {
        tableData.push(item.codeCnName);
      });
      this.chekddiqu = tableData.join(" / ");
    },
    delArea(index) {
      this.rfqSetForm.regionList.splice(index, 1);
      this.updateRfqSet({ area: this.rfqSetForm.regionList });
    },
    //当点击确定地区时
    trueCheakdiqu() {
      //关闭地区的dialog弹框
      this.addRegion = false;
      this.rfqSetForm.regionList = this.chekddiqu;
      this.updateRfqSet({ area: this.rfqSetForm.regionList });
    },

    parseCategory(item, dataList) {
      let data = item.data;
      //
      // dataList.unshift({
      //   level: data.level,
      //   categoryId: data.categoryId,
      //   categoryCnName: data.categoryCnName,
      //   parentCategoryId: data.parentCategoryId
      // })
      // 保存所有
      dataList.unshift(data);

      if (item.parent) {
        this.parseCategory(item.parent, dataList);
      }
      return dataList;
    },
    //选中类目时
    async CheckCategory(value) {
      console.info("  CheckCategory  ", value);
      console.info(
        "  CheckCategory  this.$refs.cascaderAddr.getCheckedNodes(",
        this.$refs.cascaderAddr.getCheckedNodes()
      );
      console.info("checkedCategory === ", this.checkedCategory);

      this.categoryListTmp = [];
      if (value.length) {
        if (value.length > this.maxCategoryLength) return;
        this.chekdleimu = [];
        this.rfqSetForm.categoryList = [];
        for (
          let i = 0;
          i < this.$refs.cascaderAddr.getCheckedNodes().length;
          i++
        ) {
          let tmp = this.$refs.cascaderAddr.getCheckedNodes()[i];
          let arr = tmp.pathLabels;
          this.chekdleimu.push(arr.join(">>"));
          let d = {
            labels: arr.join(">>"),
            ids: this.checkedCategory[i].join(",")
          };
          // this.rfqSetForm.categoryList.push(d )
          this.categoryListTmp.push(d);
        }
        //this.categoryName是在点击确认后才能确认，有待修改
        // this.categoryName = this.chekdleimu;
        // this.parseCategory(
        //     this.$refs.cascaderAddr.getCheckedNodes()[0],
        //     dataList
        // );
      }

      //
      // console.info(' this.rfqSetForm.categoryList == ', this.rfqSetForm.categoryList)
      // //  chekdleimu:this.chekdleimu,
      // //   checkedCategory:this.checkedCategory
      // //   for (let i = 0; i < this.chekdleimu.length; i++) {
      // //     this.rfqSetForm.categoryList.chekdleimu.push(this.chekdleimu[i])
      // //   }
      // for (let i = 0; i < this.checkedCategory.length; i++) {
      //
      //   this.rfqSetForm.categoryList[0].checkedCategory.push(this.checkedCategory[i].join(','))
      // }
    },

    //选中商机类型
    CheckOpportunity(value) {
      if (value) {
        //value是一个数组
        console.log(value, "商机类型");
        this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
          bo: value
        });
      }
    },
    trueCheakleimu() {
      console.info(
        "this.checkedCategory === ",
        this.categoryListTmp,
        this.maxCategoryLength
      );
      if (this.categoryListTmp.length > this.maxCategoryLength) {
        this.$message.error("最多只能选择" + this.maxCategoryLength + "个类目");
        return;
      }
      this.rfqSetForm.categoryList = this.categoryListTmp;
      this.categoryListTmp = [];
      //关闭dialog窗口
      this.CheckOpportunity();
      this.addCategory = false;
      this.updateRfqSet({ types: this.rfqSetForm.categoryList });
    },
    //屏蔽词添加dialog
    addshielddia() {
      let keyworsarr = [];
      this.keywords2.split("\n").forEach(item => {
        item = item.trim();
        if (
          item &&
          this.rfqSetForm.shieldList.indexOf(item) === -1 &&
          keyworsarr.indexOf(item) === -1
        ) {
          keyworsarr.push(item);
        }
      });
      if (keyworsarr.length + this.rfqSetForm.shieldList.length > 10) {
        this.$message({
          type: "error",
          message: `本次最多添加${10 - this.rfqSetForm.shieldList.length}个`
        });
        return;
      }
      this.rfqSetForm.shieldList = this.rfqSetForm.shieldList.concat(
        keyworsarr
      );
      this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
        blocks: this.rfqSetForm.shieldList
      });
      this.keywords2 = "";
      this.openshield = false;
    },
    //添加屏蔽关键词
    addshield() {
      if (this.rfqSetForm.shieldList.length < 10 && this.shield) {
        let index = this.rfqSetForm.shieldList.findIndex(item => {
          return item == this.shield;
        });
        if (index != -1) {
          return this.$message({
            type: "error",
            message: "请不要重复添加"
          });
        }
        this.rfqSetForm.shieldList.push(this.shield);
        this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
          blocks: this.rfqSetForm.shieldList
        });
        this.shield = "";
      } else if (this.rfqSetForm.shieldList.length < 50 && !this.shield) {
        this.$message({
          type: "error",
          message: "请输入屏蔽词"
        });
      } else {
        this.$message({
          type: "error",
          message: "已添加到最大长度"
        });
      }
    },
    delCategory(index) {
      //
      this.rfqSetForm.categoryList.splice(index, 1);

      this.updateRfqSet({ types: this.rfqSetForm.categoryList });
    },
    openrecommenddia() {
      if (this.rfqSetForm.recommendList.length >= this.maxRecommendLength) {
        this.$message({ type: "error", message: "已添加到最大长度" });
      } else {
        this.openrecommend = true;
      }
    },
    //点击添加推荐关键词
    addrecommend() {
      if (
        this.rfqSetForm.recommendList.length < this.maxRecommendLength &&
        this.recommend
      ) {
        let index = this.rfqSetForm.recommendList.findIndex(item => {
          return item == this.recommend;
        });
        if (index != -1) {
          return this.$message({
            type: "error",
            message: "请不要重复添加"
          });
        }
        this.rfqSetForm.recommendList.push(this.recommend);
        this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
          tips: this.rfqSetForm.recommendList
        });

        // this.bb()
        this.recommend = "";
      } else if (
        this.rfqSetForm.recommendList.length < this.maxRecommendLength &&
        !this.recommend
      ) {
        this.$message({ type: "error", message: "请输入推荐词" });
      } else {
        this.$message({
          type: "error",
          message: "已添加到最大长度"
        });
      }
    },
    //删除推荐词
    delrecommend(tag) {
      this.rfqSetForm.recommendList.splice(
        this.rfqSetForm.recommendList.indexOf(tag),
        1
      );
      this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
        tips: this.rfqSetForm.recommendList
      });
      if (this.rfqSetForm.recommendList.length < 1) {
        this.recommendbtn = false;
      } else if (this.rfqSetForm.recommendList.length == 0) {
        this.recommendshow = false;
      }
    },
    //删除屏蔽词
    delshield(tag) {
      this.rfqSetForm.shieldList.splice(
        this.rfqSetForm.shieldList.indexOf(tag),
        1
      );
      this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
        blocks: this.rfqSetForm.shieldList
      });
      if (this.rfqSetForm.shieldList.length < 10) {
        this.shieldbtn = false;
      } else if (this.rfqSetForm.shieldList.length == 0) {
        this.shieldshow = false;
      }
    },
    addrecommenddia() {
      let keyworsarr = [];
      this.keywords1.split("\n").forEach(item => {
        item = item.trim();
        if (
          item &&
          this.rfqSetForm.recommendList.indexOf(item) === -1 &&
          keyworsarr.indexOf(item) === -1
        ) {
          keyworsarr.push(item);
        }
      });
      if (keyworsarr.length + this.rfqSetForm.recommendList.length > 5) {
        this.$message({
          type: "error",
          message: `本次最多添加${5 - this.rfqSetForm.recommendList.length}个`
        });
        return;
      }
      this.rfqSetForm.recommendList = this.rfqSetForm.recommendList.concat(
        keyworsarr
      );
      this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
        tips: this.rfqSetForm.recommendList
      });
      this.keywords1 = "";
      this.openrecommend = false;
    },
    getRfqInfo() {
      this.axios
        .get("https://api.l-sou.com/subscribe/getsubsetting")
        .then(result => {
          let data = result.data;
          this.rfqSet = data || {};
          if (this.rfqSet.tips && this.rfqSet.tips.length) {
            //  已经设置了关键词信息
            this.seted = 1;
          } else {
            this.seted = 2;
          }
          this.fetchData();
          //订阅状态设置
          this.rfqSetForm.state = data.state;
          //设置通知时段起始时间和结束时间
          this.rfqSetForm.adviceStartTime = data.timestart
            ? data.timestart
            : "00:00"; //起始时间
          this.rfqSetForm.adviceEndTime = data.timeend ? data.timeend : "24:00"; //结束时间
          this.rfqSetForm.adviceStep = data.step; //间隔时间
          this.rfqSetForm.recommendList = data.tips; //推荐词
          this.rfqSetForm.shieldList = data.blocks; //屏蔽词
          this.rfqSetForm.purchasemin = data.buymin ? data.buymin : undefined; //采购最小值
          this.rfqSetForm.purchasemax = data.buymax ? data.buymax : undefined; //采购最大值
          this.rfqSetForm.value1 = data.tag; //标签
          this.rfqSetForm.value2 = data.bo; //商机
          this.rfqSetForm.eml = data.eml; //邮箱
          this.rfqSetForm.num = data.surplus; //报价席位
          this.rfqSetForm.regionList = data.area;
          this.rfqSetForm.categoryList = data.types || [];
          this.rfqSetForm.notiPlug = true;

          if (data.tips && data.tips.length) {
            data.tips.forEach(item => {
              this.kwOptions.push({
                value: item,
                label: item
              });
            });
          }
          if (data.type == 0) {
            this.rfqSetForm.notiEml = false;
          } else if (data.type == 1) {
            this.rfqSetForm.notiPlug = false;
          } else if (data.type == 2) {
            this.rfqSetForm.notiEml = true;
          }
        });
    },
    goBj(item) {
      window.open(item.base.quoteUrl);
    },
    handleCurrentChange(val) {
      this.params.pagenum = val;
      this.fetchData();
    },
    checkIsNotEmpty(Obj) {
      for (const objKey in Obj) {
        return true;
      }

      return false;
    },

    objectToQuery(obj) {
      let params = [];
      for (const key in obj) {
        if (obj[key]) {
          if (Array.isArray(obj[key])) {
            obj[key].forEach((item, index) => {
              console.info(
                "`${key}[${index}]=${item}` == ",
                `${key}[${index}]=${item}`
              );
              params.push(encodeURI(`${key}[${index}]=${item}`));
            });
          } else params.push(`${key}=${encodeURI(obj[key])}`);
        }
      }
      return params.length ? "?" + params.join("&") : "";
    },
    fetchData(params) {
      if (!params) {
        params = this.params;
      }

      params = this.objectToQuery(params);
      console.info("params = = ", params);
      this.axios
        .get(`https://api.l-sou.com/subscribe/getmyrfq` + params)
        .then(result => {
          let tmpData = result.data.data;
          if (tmpData && tmpData.length) {
            tmpData.forEach(item => {
              if (item.desc) {
                item.isLoged =
                  this.checkIsNotEmpty(item.desc) ||
                  this.checkIsNotEmpty(item.buyer || {});
              } else {
                item.isLoged = false;
              }
              // 旧版插件 没有buyerNew  , 适配旧版插件提交的
              if (!item.buyerNew) {
                item.buyerNew = {};
              }
              if (item.base.tags && item.base.tags.length) {
                for (let i = 0; i < item.base.tags.length; i++) {
                  let tagTmp = item.base.tags[i];
                  if (tagTmp.tagName === "RFQ_MKT_ST_28101") {
                    item.tagImgUrl =
                      "https://img.alicdn.com/imgextra/i2/O1CN01PcMX9S1N2XZUXudyV_!!6000000001512-2-tps-219-60.png";
                  } else if (tagTmp.tagName === "RFQ_MKT_ST_28102") {
                    item.tagImgUrl =
                      "https://img.alicdn.com/imgextra/i4/O1CN01YOnQWO1U5TPv2aEnh_!!6000000002466-2-tps-219-60.png";
                  } else if (tagTmp.tagName === "RFQ_MKT_ST_28104") {
                    item.tagImgUrl =
                      "https://img.alicdn.com/imgextra/i3/O1CN017cjd8q1kOjvmZIDzk_!!6000000004674-2-tps-219-60.png";
                  }
                }
              }
            });
          }
          this.tableData = result.data.data;
          this.total = result.data.total;
        });
    },
    getInjectId() {
      this.axios.get("/index/getinject").then(data => {
        this.injectId = data.data;
      });
    },
    tableSelectAll(value) {
      this.dcsjtableData = value;
      if (this.dcsjtableData.length > 0) {
        this.dcsj = false;
      } else {
        this.dcsj = true;
      }
    },
    handleSelectionChange(e) {
      console.info("select ", e);
      this.checkedData = e;
    },

    // ------------ rfq 设置 start ------------
    makeSure() {
      if (this.rfqSetForm.recommendList.length == 0) {
        this.$message({ type: "error", message: "请添加推荐词" });
      } else {
        this.$message.success("保存成功");
        this.getRfqInfo();
      }
    },

    async updateRfqSet(data) {
      console.info("data === ", data);

      await this.axios.post(
        "https://api.l-sou.com/subscribe/updatesubsetting",
        data
      );
      this.$message.success("修改成功");

      //  通知插件修改
      chrome.runtime.sendMessage(
        this.injectId,
        {
          type: "updateRFQ"
        },
        (e, a) => {
          console.info("插件 updateRFQ 返回结果", e, a);
        }
      );
    },
    changgeStartTime(value) {
      this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
        timestart: value
      });
    },
    changgeEndTime(value) {
      this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
        timeend: value
      });
    },
    NoticeRoom() {
      this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
        step: this.rfqSetForm.adviceStep
      });
    },
    notiEmlChange(e) {
      if (!this.rfqSetForm.eml) {
        this.emlDialog = true;
        return;
      }
      this.rfqSetForm.notiEml = e;
      if (this.rfqSetForm.notiPlug && this.rfqSetForm.notiEml) {
        this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
          type: 2
        });
      } else if (this.rfqSetForm.notiPlug && !this.rfqSetForm.notiEml) {
        this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
          type: 0
        });
      } else if (!this.rfqSetForm.notiPlug && this.rfqSetForm.notiEml) {
        this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
          type: 1
        });
      }
    },
    //点击发送验证码
    SendVer() {
      if (
        /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(
          this.ruleForm.emlinput
        )
      ) {
        this.countNub = 60;
        this.kg = true;
        this.timer = setInterval(this.valChang, 1000);
        this.getEmailCode();
      }
    },
    //获取邮箱验证码
    getEmailCode() {
      this.axios
        .post("https://api.l-sou.com/users/sendemcode", {
          emaddr: this.ruleForm.emlinput
        })
        .then(result => {
          this.emailcode = result.data;
        });
    },
    valChang() {
      this.countNub--;
      if (this.countNub === 0) {
        clearInterval(this.timer);
        this.countNub = "发送验证码";
        this.kg = false;
      }
    },
    //立即绑定邮箱
    emlConfirm(formName) {
      if (!this.emailcode) {
        return this.$message({ type: "error", message: "请点击接收验证码" });
      }
      if (!this.ruleForm.verificationCode) {
        return this.$message({ type: "error", message: "请输入验证码" });
      }
      this.axios
        .post("https://api.l-sou.com/users/emload", {
          emaddr: this.ruleForm.emlinput,
          uuid: this.emailcode,
          code: this.ruleForm.verificationCode
        })
        .then(result => {
          console.log(result);
        });
      this.rfqSetForm.eml = this.ruleForm.emlinput;

      this.$message({
        showClose: true,
        message: "绑定邮箱成功",
        type: "success"
      });
      this.emlDialog = false;
      //修改成邮箱通知
      this.rfqSetForm.notiEml = true;
      this.$refs[formName].resetFields(); //绑定成功后清空内容
      //如果绑定成功，需要判断插件通知是否绑定，如果绑定则传2过去，如果插件通知没有绑定，则传1过去
      if (this.rfqSetForm.notiEml && this.rfqSetForm.notiPlug) {
        this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
          type: 2
        });
      } else if (!this.rfqSetForm.notiEml && !this.rfqSetForm.notiPlug) {
        this.axios.post("https://api.l-sou.com/subscribe/updatesubsetting", {
          type: 1
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
//.el-radio {
//  position: absolute;
//  display: none;
//}
.overFlow {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  //display: -webkit-inline-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.stickyHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.width100 {
  width: 100px;
}

.width180 {
  width: 180px;
}

.el-tag {
  margin: 0 5px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #313131;
}

.rule {
  font-size: 16px;
}

.light {
  font-size: 13px;
  color: rgb(140, 140, 140);
}

.itk {
  font-size: 14px;
}

.main {
  min-width: 780px;
  background-color: #fff;
  border-radius: 12px;
  margin: 15px 0;
  padding: 28px;
}

.main .box {
  border-radius: 12px;
  box-shadow: 0 2px 5px 0 #ddd;
  margin-top: 30px;
  padding: 20px;
  color: #666;
}

.main .box .bindalsaddr {
  margin: 0 10px;
}

.box .n1 {
  display: flex;
  align-items: center;
}

.box .n1 .hlhs {
  /*background-color: #FF6A00;*/
  width: 2px;
  height: 16px;
}

.box .n1 .txt {
  margin-left: 10px;
}

.box .n2 .item {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 20px;
}

.item {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 15px;
}

.box .n2 .item .action {
  color: #ff6a00;
}

.box .n2 .item .action:hover {
  cursor: pointer;
}

.box .n2 .item .itv {
  margin-left: 20px;
}

.item button {
  height: 30px;
  padding: 0 12px;
  border: 1px solid #fff;
  border-radius: 8px;
  background-color: #ff6a00;
  color: #fff;
}

button:hover {
  cursor: pointer;
}

button:active {
  color: #eee;
}

h1 {
  margin: 20px auto;
  height: 1px;
  background-color: #eee;
}

.item > .el-input__inner {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}

.el-date-editor > .el-input__prefix {
  display: none;
}

> .el-input__icon {
  line-height: unset;
}

> .el-radio__input.is-checked .el-radio__inner {
  border-color: #ff6a00;
  background-color: #ff6a00;
}

> .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: #ff6a00;
  background-color: #ff6a00;
}

> .el-radio__input.is-checked + .el-radio__label {
  color: #666666;
}

> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #666666;
}

> .el-radio__label {
  color: #666666;
}

.dialog-footer > .el-button {
  background-color: #ff6a00;
  border: none;
  color: #fff;
}

.dialog-footer > .el-button:hover {
  background-color: #ffd1b3;
  border-color: #fff0e6;
  color: #f60;
}

.pointers {
  cursor: pointer;
}

.content-box {
  margin-top: 20px;
  background: white;
  border-radius: 20px;
  padding: 20px 10px 0;
}

::v-deep .sxdialog .el-dialog__body {
  padding: 0px;
  font-size: 15px;
}

::v-deep .sxdialog .dialog__title {
  font-size: 14px;
}

::v-deep .item[data-v-54a1a696] {
  margin-top: 0px;
}
</style>
