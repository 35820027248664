<template>
    <div>
      <el-popover
          placement="top"
          width="250"
          trigger="hover"
      >
        <div style="font-size: 12px"><i style="color: #ff6600" class="el-icon-warning"></i>未登录Alibaba账户<br><hr>
          需要登录Alibaba账号才能获取详细数据，
          <br>请登录后点击“一键补全”按钮补全数据。
          <br><el-button size="mini" type="primary" @click="goGoodPage">去登录</el-button>
        </div>
        <div class="pointers" style="color: #ccc"  slot="reference">获取失败<i class="el-icon-question"></i></div>
      </el-popover>
    </div>
</template>

<script>
export default {
  props:{
    loginurl:{

      type:String,
      default:''
    }
  },
  methods:{
    goGoodPage(){
      window.open(this.loginurl)
    }

  }
}
</script>

<style scoped>
.pointers{
  cursor: pointer;

}
</style>